import { FaRegBuilding } from "react-icons/fa";
import { FaHouse } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import React from "react";

const Footer = () => {
  return (
    <footer className="relative text-center bg-white z-2 text-neutral-600 ark:text-neutral-200 lg:text-left pt-[50px] z-[3] border border-t-natutal-600">
      {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
      <div className="py-10 mx-6 text-center md:text-left">
        <div className="flex flex-col items-center justify-between gap-5 md:flex-row">
          {/* <!-- TW Elements section --> */}
          <div className=" max-w-[500px]">
            <h6 className="flex items-center justify-center mb-4 font-semibold uppercase md:justify-start">
              <FaRegBuilding classname="w-5 h-5 mr-3" />
              Marcin Białek Geschaftsfuhrer
            </h6>
            <p></p>
          </div>

          {/* <!-- Contact section --> */}
          <div>
            <h6 className="flex justify-center mb-4 font-semibold uppercase md:justify-start">
              Contact
            </h6>
            <p className="flex items-center justify-center mb-4 md:justify-start">
              <FaHouse classname="w-5 h-5 mr-3" />
              Husarów 9 Straße, 71-005 Szczecin
            </p>
            <a
              href="mailto:info@lechbud.de"
              className="flex items-center justify-center mb-4 cursor-pointer md:justify-start"
            >
              <MdEmail classname="w-5 h-5 mr-3" />
              info@lechbud.de
            </a>
            <a
              href="tel:512 548 812"
              className="flex items-center justify-center mb-4 cursor-pointer md:justify-start"
            >
              <FaPhoneAlt classname="w-5 h-5 mr-3" /> +48 512 548 812
            </a>
          </div>
        </div>
      </div>

      {/* <!--Copyright section--> */}
      <div className="p-6 text-center bg-neutral-200 ">
        <span>© 2024 Copyright:</span>
        <a className="font-semibold text-neutral-600 " href="./">
          LechBud
        </a>
        <br />
        <a
          className="font-semibold text-neutral-600"
          href="https://www.hyperbaystudio.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          DESIGNED & POWERED BY HYPERBAY.
        </a>
      </div>
    </footer>
  );
};
export default Footer;
