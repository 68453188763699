import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);
  const location = useLocation();

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Close navbar when location changes
  useEffect(() => {
    setNav(false);
  }, [location]);

  // Array containing navigation items with their respective paths
  const navItems = [
    { id: 1, text: "Home", path: "/" },
    { id: 2, text: "Angebot", path: "/angebot" },
    { id: 3, text: "Blogs", path: "/blogs" },
    { id: 4, text: "Realisierung", path: "/realisierung" },
    { id: 5, text: "Über uns", path: "/uberuns" },
    { id: 6, text: "Kontakt", path: "/kontakt" },
  ];

  return (
    <div className="absolute top-0 left-0 z-10 flex items-center w-full h-24 px-4 mx-auto text-black bg-oppacity-100 mt-[20px] justify-between">
      {/* Logo */}
      <img src={logo} alt="logo" className="w-[170px] h-[100px]" />

      {/* Desktop Navigation */}
      <ul className="hidden md:flex">
        {navItems.map((item) => (
          <li
            key={item.id}
            className={`duration-300 cursor-pointer hover:text-[#FFA500] font-semibold text-sm ${
              location.pathname === item.path
                ? "text-[#FFA500] "
                : " text-black "
            }`}
          >
            <Link to={item.path} className=" lg:p-4 m-2">
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={25} /> : <AiOutlineMenu size={25} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
        }
      >
        {/* Mobile Logo */}
        <img src={logo} alt="logo" className="w-[170px] h-[100px] mt-[20px]" />

        {/* Mobile Navigation Items */}
        {navItems.map((item) => (
          <li
            key={item.id}
            className={`border-b p-4  duration-300 hover:text-[#FFA500] font-semibold cursor-pointer border-gray-600 ${
              location.pathname === item.path ? "text-[#FFA500]" : "text-black"
            }`}
          >
            <Link className="p-4" to={item.path}>
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Navbar;
