import React, { Suspense, lazy } from "react"; // Import React and necessary hooks
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";

// Lazy load pages
const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact"));
const About = lazy(() => import("./pages/About"));
const Realizacja = lazy(() => import("./pages/Realizacja"));
const Offerta = lazy(() => import("./pages/Offerta"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const AllBlogs = lazy(() => import("./components/Blogs/AllBlogs"));
const BlogPage = lazy(() => import("./components/Blogs/BlogPage"));
const Blog1 = lazy(() => import("./components/Blogs/Blog1"));

function App() {
  return (
    <>
      <Navbar />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/angebot" element={<Offerta />} />
          <Route path="/realisierung" element={<Realizacja />} />
          <Route path="/uberuns" element={<About />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/blog/:id" element={<BlogPage />} />
          <Route path="/blog/4" element={<Blog1 />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
